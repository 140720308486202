
declare let gtag: Function // eslint-disable-line
import { defineComponent } from 'vue'
import Button from '@/components/elements/Button.vue'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

export default defineComponent({
  name: 'NotFound',

  components: {
    Button,
  },

  setup() {
    gtag('event', '404')

    const lang = Cookies.get(app_cookie.lang) ?? 'ja'
    const contents = {
      ja: {
        text: 'リクエストされたページは<br />見つかりませんでした。',
        btn: 'はじめにもどる',
      },
      en: {
        text: 'We couldn’t find the page you<br> were looking for. ',
        btn: 'Return',
      },
      zh: {
        text: '未找到您要查看的页面。',
        btn: '返回首页',
      },
      ko: {
        text: '요청하신 페이지를 찾을 수 없습니다.',
        btn: '처음으로 돌아가기',
      },
    }

    return {
      lang,
      contents,
    }
  },
})
