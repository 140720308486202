<template>
  <div class="notfound">
    <div class="notfound_contents">
      <div class="notfound_inner">
        <h1 class="notfound_title">
          <img src="img/notFound/title.svg" :alt="contents[lang].title" />
        </h1>
        <p class="notfound_text" v-html="contents[lang].text" />
        <Button
          color="border"
          shadow="white"
          :text="contents[lang].btn"
          :handleClick="() => $router.push('/')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
declare let gtag: Function // eslint-disable-line
import { defineComponent } from 'vue'
import Button from '@/components/elements/Button.vue'
import Cookies from 'js-cookie'
import { app_cookie } from '@/utility/config'

export default defineComponent({
  name: 'NotFound',

  components: {
    Button,
  },

  setup() {
    gtag('event', '404')

    const lang = Cookies.get(app_cookie.lang) ?? 'ja'
    const contents = {
      ja: {
        text: 'リクエストされたページは<br />見つかりませんでした。',
        btn: 'はじめにもどる',
      },
      en: {
        text: 'We couldn’t find the page you<br> were looking for. ',
        btn: 'Return',
      },
      zh: {
        text: '未找到您要查看的页面。',
        btn: '返回首页',
      },
      ko: {
        text: '요청하신 페이지를 찾을 수 없습니다.',
        btn: '처음으로 돌아가기',
      },
    }

    return {
      lang,
      contents,
    }
  },
})
</script>

<style lang="scss" scoped>
.notfound {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: var.$color-primary-bg;
  background-image: url('/img/common/dot_blue.svg');
}
.notfound_contents {
  text-align: center;
  margin: 20px;
  border-radius: 20px;
  background-color: #fff;
  height: calc(100% - 40px);
  padding: 0 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notfound_title {
  margin-bottom: 15px;
}
.notfound_text {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 45px;
}
</style>
